.config-wrapper {
  position: absolute;
  width: 1395px;
  height: 810px;
  left: 90px;
  top: 187px;
}
.config-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  position: absolute;
  width: 718px;
  height: 561px;
  left: 32px;
  top: 92px;
}
.unit-config-label {
  position: absolute;
  //width: 83px;
  //height: 20px;
  left: 475px;
  top: 94px;
}
.unit-config-text {
  position: absolute;
  //width: 31px;
  //height: 20px;
  left: 475px;
  top: 117px;
}
.branch-config-label {
  position: absolute;
  //width: 137px;
  //height: 20px;
  left: 237px;
  top: 94px;
}
.branch-config-text {
  position: absolute;
  //width: 5px;
  //height: 20px;
  left: 237px;
  top: 117px;
}
.diameter-config-label {
  position: absolute;
  //width: 135px;
  //height: 20px;
  left: 0px;
  top: 94px;
}
.diameter-config-text {
  position: absolute;
  //width: 17px;
  //height: 20px;
  left: 0px;
  top: 117px;
}
.waterwash-wrap {
  position: absolute;
  //width: 644px;
  //height: 164px;
  //left: 0px;
  top: 264px;
}
#water-wash {
  height: 40px;
}
.waterwash-section-1 {
  position: absolute;
  //width: 376px;
  height: 42px;
  left: 0px;
  top: 42px;
}
.wash-used-label {
  position: absolute;
  width: 134px;
  //height: 20px;
  left: 0px;
  bottom: 92px;
}
.wash-used-text {
  position: absolute;
  //width: 25px;
  //height: 20px;
  left: 0px;
  bottom: 69px;
}
.water-inj-label {
  position: absolute;
  width: 139px;
  //height: 20px;
  left: 237px;
  bottom: 92px;
}
.water-inj-text {
  position: absolute;
  width: 100px;
  height: 20px;
  left: 237px;
  bottom: 69px;
}
.waterwash-section-2 {
  position: absolute;
  //width: 453px;
  height: 42px;
  left: 0px;
  top: 110px;
}
.waterwash-freq-label {
  position: absolute;
  width: 110px;
  height: 20px;
  left: 0px;
  bottom: 102px;
}
.waterwash-freq-text {
  position: absolute;
  width: 80px;
  height: 20px;
  left: 0px;
  bottom: 79px;
}
.inj-eqp-label {
  position: absolute;
  width: 173px;
  //height: 20px;
  left: 237px;
  bottom: 102px;
}
.inj-eqp-text {
  position: absolute;
  width: 220px;
  left: 237px;
  bottom: 79px;
}
.neutro-group {
  flex: none;
  flex-grow: 0;
}
.neutro-inj-label {
  position: absolute;
  //width: 176px;
  //height: 20px;
  left: 0px;
  bottom: 152px;
}
.neutro-inj-text {
  position: absolute;
  //width: 146px;
  //height: 20px;
  left: 0px;
  bottom: 130px;
}
.neutro-stage-label {
  position: absolute;
  //width: 190px;
  //height: 20px;
  left: 237px;
  bottom: 152px;
}
.neutro-stage-text {
  position: absolute;
  //width: 71px;
  //height: 20px;
  left: 237px;
  bottom: 130px;
}
.preflash-grp-wrapper {
  position: absolute;
  top: 31px;
  display: flex;
}
.group-wrapper {
  position: absolute;
  //height: 43px;
  //left: 0px;
  top: 42px;
  display: flex;
}
.wd-644 {
  width: 644px;
}
.wd-580 {
  width: 580px;
}
.wd-427 {
  width: 427px;
}
.preflash-config-label {
  position: absolute;
  //width: 147px;
  //height: 20px;
  left: 475px;
}
.preflash-height{
  height:50px;
}
@media only screen and (max-width: 1400px) and (min-width: 1300px){
  .preflash-height{
    height:70px;
  }
}
.preflash-config-text {
  position: absolute;
  width: 260px;
  //height: 20px;
  left: 475px;
  top: 23px;
}
.desalt-config-label {
  position: absolute;
  //width: 56px;
  //height: 20px;
  left: 237px;
}
.desalt-config-text {
  position: absolute;
  //width: 71px;
  //height: 20px;
  left: 237px;
  top: 23px;
}
.overhead-config-label {
  position: absolute;
  //width: 158px;
  //height: 20px;
}
.overhead-config-text {
  position: absolute;
  //width: 71px;
  //height: 20px;
  //left: 0px;
  top: 23px;
}
.preview-frame {
  box-sizing: border-box;
  // position: absolute;
  // width: 620px;
  min-height: 98%;
  // left: 760px;
  // top: 92px;
  background: #f8faf9;
  border: 1px solid #ced7d4;
}
.preview-config {
  position: absolute;
  width: 50%;
  height: 36px;
  left: 37%;
  top: 13%;
}
#stage-preview .bh-illustration--image {
  max-width: 540px;
}
#stage-previewEdit .bh-illustration--image {
  max-width: 500px;
}
.wd-100 {
  width: 100%;
  height: 43px;
}
.wd-100-ht-55 {
  width: 100%;
  height: 55px;
}
.card-wrapper {
  min-height: 630px;
  margin-left: 30px;
  margin-right: 30px;
  box-sizing: border-box;
  // position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 126px;
  background: #ffffff;
  border: 1px solid #ced7d4;
  border-radius: 4px;
}
#neutro-list .bh-list-wrapper {
  border-bottom: none;
}
.subunit-tile-wrapper .bh-title-wrapper__container .bh-title-wrapper__copies {
  padding: 11px;
}
.div-ht-20{
  height:20px;
}
.info-avatar{
  background-color: #fcf0d4 !important;
  border: 1px solid;
  color: #fcf0d4 !important;
  position: relative !important;
  top: -125%;
}
.info-avatar-overhead{
  left: 56%;
}
.info-avatar-preflash{
  left: 52%;
}
.info-avatar-preflash-c{
  left: 42%;
}
.info-avatar-position{
  left: 48%;
}
.info-avatar-neutr{
  left: 61%;
}
.info-avatar-desalt{
  left:20%;
}
.info-avatar-washinj{
  left:37%;
}
.info-avatar-washfreq{
  left:40%;
}
.info-avatar-injeqp{
  left:46%;
}
.info-avatar-neutrinj{
  left:68%;
}
@media only screen and (max-width: 1400px) and (min-width: 1300px){
  .info-avatar-overhead {
    left: 84%;
}
.info-avatar-desalt {
  left: 29%;
}
.info-avatar-preflash {
  left: 77%;
}
.info-avatar-preflash-c{
  left:  36%;
}
.info-avatar-position {
  left: 72%;
}
.info-avatar-washinj {
  left: 55%;
}
.info-avatar-washfreq {
  left: 59%;
}
.info-avatar-injeqp {
  left: 69%;
}
.info-avatar-neutr {
  left: 90%;
}
.info-avatar-neutrinj {
  left: 99%;
}
}
@media only screen and (max-width: 1600px) and (min-width: 1500px){
  .info-avatar-overhead {
    left: 74%;
}
.info-avatar-desalt {
  left: 25%;
}
.info-avatar-preflash {
  left: 68%;
}
.info-avatar-preflash-c {
  left: 32%;
}
.info-avatar-position {
  left: 64%;
}
.info-avatar-washinj {
  left: 48%;
}
.info-avatar-washfreq {
  left: 53%;
}
.info-avatar-injeqp {
  left: 60%;
}
.info-avatar-neutr {
  left: 80%;
}
.info-avatar-neutrinj {
  left: 89%;
}
}
@media only screen and (max-width: 1300px) and (min-width: 1200px){
.info-avatar-overhead {
  left: 93%;
}
.info-avatar-desalt {
  left: 33%;
}
.info-avatar-preflash {
  left: 86%;
}
.info-avatar-preflash-c {
  left: 40%;
}
.info-avatar-position {
  left: 81%;
}
.info-avatar-washinj {
  left: 62%;
}
.info-avatar-washfreq {
  left: 66%;
}
.info-avatar-injeqp {
  left: 76%;
}
.div-ht-20{
  height:40px;
}
.info-avatar-neutr {
  left: 78%;
}
.info-avatar-neutrinj {
  left: 86%;
}
.preflash-height {
  height: 68px;
}
}
@media only screen and (max-width: 1700px) and (min-width: 1600px){
  .info-avatar-overhead {
    left: 66%;
  }
  .info-avatar-desalt {
    left: 23%;
  }
  .info-avatar-preflash {
    left: 60%;
  }
  .info-avatar-preflash-c {
    left: 28%;
  }
  .info-avatar-position {
    left: 56%;
  }
  .info-avatar-washinj {
    left: 42%;
  }
  .info-avatar-washfreq {
    left: 46%;
  }
  .info-avatar-injeqp {
    left: 54%;
  }
  .info-avatar-neutr {
    left: 70%;
  }
  .info-avatar-neutrinj {
    left: 78%;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1800px){
  .info-avatar-overhead {
    left: 62%;
  }
  .info-avatar-desalt {
    left: 23%;
  }
  .info-avatar-preflash {
    left: 58%;
  }
  .info-avatar-preflash-c {
    left: 26%;
  }
  .info-avatar-position {
    left: 54%;
  }
  .info-avatar-washinj {
    left: 40%;
  }
  .info-avatar-washfreq {
    left: 44%;
  }
  .info-avatar-injeqp {
    left: 50%;
  }
  .info-avatar-neutr {
    left: 65%;
  }
  .info-avatar-neutrinj {
    left: 72%;
  }
}
.coker-config-wrapper{
  margin-left: 20px;
  margin-top: 20px;
}
.mrg-left-20{
  margin-left: 20px;
}
.mrg-left-50{
  margin-left:50px;
}
.mrg-top-15{
  margin-top: 15px;
}
.mrgn-top-5{
  margin-top: 5px;
}