.bread-crum {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 32px;
  gap: 8px;

  position: absolute;
  width: 100%;
  height: 44px;
  left: 20px;
  top: 71px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Common/Primary */

  border-bottom: 1px solid #ced7d4;
}
.new-sim {
  position: absolute;
  width: 1318px;
  height: 50px;
  left: 80px;
  top: 134px;
}
.simulation-table {
  display: block;
  padding-top: 200px;
  padding-left: 72px;
  width: 95%;
}

.all-simulation-table {
  display: block;
  padding-left: 4%;
  width: 100%;
  margin-bottom: 20%;
}

.status-span {
  float: right;
  position: flex;
  padding-left: 11px;
  padding-top: -2px;
  margin-top: -2px;
}
.bh-add-input {
  position: absolute;
  top: 2%;
  left: 87%;
  //width:12%;
}

.bh-menu__li:hover {
  background-color: #c6f7e2;
  color: black;
}

.zindex {
  z-index: 1;
}

.simTabs {
  margin-bottom: 25px;
  // position: absolute;

  width: 100%;
}

.simButtonTabs {
  position: absolute;
  top: 32%;
  width: 96%;
  left: 5.4%;
}

.bh-pagination {
  display: flex;
}

.dateRangeFilter {
  display: flex;
  margin: 16.4% 0px 0px 56.8%;
}

.bhClearButton {
  position: absolute;
  top: 32%;
  right: 0%;
}
.rt-123 {
  right: 123px;
}

#filterDate #filterDate__vaadin-date-picker span {
  display: none;
}

#filterDate .bh-date-range-picker__divider {
  padding-top: 10px;
}

#filterDate #filterDate__vaadin-date-picker {
  width: 200px;
  padding-top: 0px !important;
  margin-top: -10px !important;
}

.bhSubmitButton {
  position: absolute;
  top: 11%;
  right: 2%;
  z-index: 1;
}

.new-sim-wrapper {
  width: 100%;
  height: 800px;
  position: absolute;
  // padding: 14px 45px 16px 25px;
}

.width-alter {
  margin-left: 2.5%;
  width: 102%;
  padding-bottom: 50px;
}
.simulation-report-footer {
  position: absolute;
  margin-left: 1%;
  bottom: 5%;
  height: 10px;
  width: 100%;
}
.sim_container {
  width: 100%;
}
.new-sim-card {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 95%;
  left: 72px;
  top: 219px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Form/Default */

  border: 1px solid #ced7d4;
  border-radius: 4px;
}
#new-simulation-card .MuiCardHeader-title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
}
#newsim-menu .MuiMenuItem-gutters {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}
#newsim-menu .MuiMenu-list {
  width: 250px;
}
#newsim-menu .MuiMenuItem-gutters:hover {
  background-color: #c6f7e2;
  color: black;
}

.total-limit {
  top: 5%;
  position: absolute;
  height: 37px;
  left: 34%;
  width: 32%;
}
.txtsimulationcomment .bh-text-area__input{
  border: 1px dashed #ced7d4;
  box-shadow: none;
  cursor: default;
}
.txtsimulationcomment .bh-form-message{
 display: none;
}
.view-comment {
  width: 100%;
  height: 100%;
}
.view-comment .MuiDialog-paperScrollPaper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: unset;
  position: absolute;
  width: 783px;
  height: 500px;

  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
}
.view-comment .MuiDialogContent-dividers {
  width: 94.6%;
}
.view-comment .MuiDialogActions-spacing {
  padding-left: 77% !important;
}
.hidden-date-color{
  color : #65D6AD
}
.hidden-text-color{
  color : #BDBDBD
}

.new-simulation-div {
  margin-left: 20px;
  margin-top: -17px;
  height: 30px;
}

.new-simulation-divider {
  margin-top: -15px
}