.add-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
}
.add-user-email {
  display: inline-flex;
  padding: 0px;
  gap: 20px;
}
.add-user-verify {
  display: inline-flex;
  padding: 0px;
  gap: 10px;
}
.entitle-box {
  display: inline-flex;
  padding: 0px;
  width: 100%;
}
.verify-user {
  margin-left: 10px;
  margin-top: 10px;
}
#btnVerifyUser .bh-button {
  min-width: 24px !important;
}
.user-section {
  width: 30%;
  float: left;
}
#txt-email .bh-text-input__input {
  width: 221px;
}
#manage-user-modal .bh-modal__container {
  width: 85% !important;
  top: 52px !important;
  left: 7% !important;
  z-index: 40000;
}
#manage-user-modal .bh-modal__body {
  max-height: 370px !important;
}
#txt-name .bh-text-input__input {
  width: 312px;
}
.role-section {
  padding-top: 26.6px;
}
#txt-username .bh-text-input__input {
  width: 220px;
}
.user-divider {
  top: 190px;
  position: absolute;
  width: 96%;
}
.footer-divider {
  top: 82%;
  position: absolute;
  width: 100%;
}
.pd-20 {
  padding-top: 20px;
}
.role-drd {
  width: 312px;
}
.role-drd .bh-dropdown__input {
  width: 257px;
}
.user-panel-footer {
  top: 95%;
  position: absolute;
  display: inline-flex;
}
#btnBack {
  padding-left: 247px;
}
#btnAddNewUser {
  padding-left: 20px;
}
#btnApply {
  padding-left: 20px;
}
.user-name-wrapper {
  background: #ffffff;
  border: 1px solid #02a783;
  border-radius: 4px;
  width: 460px;
  height: 326px;
  margin-left: 20px;
  margin-top: 10px;
}
.user-chk-wrapper {
  width: 100%;
}
.pd-55 {
  padding-top: 55px;
}
.combo-box-customer .MuiFormControl-root .MuiInputBase-root {
  width: 312px !important;
  height: 44.6px !important;
}
.combo-box-customer .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  height: 0.4375em !important;
}
.combo-box-customer {
  padding-top: 3px;
}
.entitle-box .MuiBox-root .customer-list {
  height: 295px !important;
}
.entitle-box .MuiBox-root .refinery-list {
  height: 295px !important;
}
.div-hide {
  display: none;
}
.div-show {
  display: inline-flex;
}
.div-block {
  display: block;
}
.combo-box-customer .MuiAutocomplete-popper {
  height: 10px !important;
}
.customer-label {
  color: #121212 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.25px !important;
}
.cust-item .MuiListItemText-primary {
  color: #121212 !important;
  font-family: 'Noto Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.cust-item .MuiFormControlLabel-label {
  color: #121212 !important;
  font-family: 'Noto Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.customer-list .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.refinery-list .MuiListItemButton-gutters {
  height: 45px;
}
.user-error {
  width: 97.4%;
  position: relative;
  top: 12px;
}
.alert-box-info {
  margin-top: 10px;
  background-color: #e5f6fd !important;
  border: 1px solid;
  border-color: #1e96d7;
}
.pd-15 {
  padding-top: 15px;
}
.wd-130 {
  width: 130px;
}
.MuiAutocomplete-popper {
  z-index: 999999 !important;
}
