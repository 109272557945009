.import-asset-wrapper {
  display: flex;
  width: 896px;
}
.source-drd {
  width: 245px;
  height: 66px;
}
.source-drd .bh-dropdown__input {
  width: 192px;
}
.customer-div {
  padding-left: 13px;
  width: 245px;
}
.refinery-div {
  padding-left: 11px;
  width: 245px;
}
.customer-div .MuiTextField-root .MuiInputBase-root {
  height: 43.5px !important;
}
.refinery-div .MuiTextField-root .MuiInputBase-root {
  height: 43.5px !important;
}
.btn-asset-search {
  padding-top: 24px;
  padding-left: 9px;
}
.search-list {
  width: 896px;
  height: 289px;
}
.status-text {
  padding: 5px;
  margin: 30px;
  border: 1px solid;
  border-radius: 4px;
}
.status-text-pending {
  color: #ff6666;
  background-color: #fefbe6;
  border-color: #ff6666;
}
.status-text-onboarded {
  color: #02a783;
  background-color: #effcf6;
  border-color: #02a783;
}
#empty-box {
  padding-top: 7%;
}
.import-modal {
  width: 100%;
  height: 100%;
}
.import-modal .MuiDialog-paperScrollPaper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: unset;
  position: absolute;
  height: 620px;
  max-width: 95%;
  margin-top: 8%;

  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
}
.import-modal .MuiDialogContent-dividers {
  width: 95%;
}
.import-modal .MuiDialogActions-spacing {
  padding-left: 27.1% !important;
}
#import-asset-title {
  width: 95.5%;
  overflow: hidden;
  padding: var(--spacing-padding-medium);
  background-color: var(--color-fill-common-tertiary);
  border-bottom: var(--effect-border-width-regular) solid var(--color-border-common-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.import-cta-container {
  display: inline-flex;
  padding-left: 400px;
}

.footer-cta-wrapper {
  width: 72%;
  background-color: #ebefee;
  border-top: 1px solid #ced7d4;
  padding: 20px;
}
.import-cta-container .bh-button__container .bh-button .bh-button__label {
  font-size: 12px !important;
}
#alert-message-import{
  position: fixed;
  top: 79%;
  width: 92.8%;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: #121212;
  margin-top: 3px;
}
