.empty-notification {
    width: 385px;
    height: 100px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.empty-notification-sub-div {
    display: flex;
    justify-content: space-between;
    padding-top: 10px !important;
    padding-left: 10px !important;
}

.empty-notification-font {
    font-size: 0.9rem !important;
    font-family: Poppins, sans-serif !important;
    font-weight: bold !important;
}

.empty-notification-icon {
    cursor: pointer;
    font-size: 1rem !important;
}