.page-wrapper {
  position: absolute;
  left: 58px;
  right: 0px;
  top: 73px;
  bottom: 0px;
  min-height: 1000px;
  /* Background / bg-1 */

  background: #f8faf9;
}

.plant-info {
  width: 100%;
  height: 50px;

  top: 32px;
}
#btn-change-unit .bh-button .bh-button__label {
  // width: 89px; need to check
  width: 88px;
  height: 20px;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text / Primary */

  color: #1a2321;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
#btn-new-sim .bh-button .bh-button__label {
  width: 111px;
  height: 20px;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* 🎨 Text/CTA/Primary */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
