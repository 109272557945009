.report {
  top: 118px;
  width: 549px;
  height: 496px;
}
.report-card-wrapper {
  margin-bottom: 10px;
  .bh-card--content.expandable.open {
    max-height: initial !important;
  }
}
.report-detail {
  /* Borderless cards */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 352px;
  height: 457px;
  right: 10px;
  top: 84px;

  /* 🎨 Fill/Common/Primary */

  background: #f8faf9;
}
.primary-report-container {
  display: flex;
}

.report-wrapper {
  border: 1px solid #ced7d4;
}
.risk-label {
  margin: 3px 0px 0px 5px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: rgba(89, 89, 89, 1);
}
.corrosion-risk-tooltip {
  display: flex;
  margin-left: 10px;
  margin-bottom: 10px;
}
.report-accordion-container {
  margin-top: 10px;
  width: 25%;
  margin-left: 4%;
}
#report .konvajs-content{
  width:1380px;
  height:600px;
}
@media only screen and (max-width: 2000px) and (min-width: 1920px){
  #report .konvajs-content{
    width: 1380px !important;
    height:600px !important;
  }
  #report canvas{
    width: 1380px !important;
    height:600px !important;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1200px){
  #report .konvajs-content{
    width: 1079px !important;
    height: 455px !important;
  }
  #report canvas{
    width: 1006px !important;
    height: 455px !important;
  }
  .report-accordion-container {
    width: 30%;
    margin-left: -1%;
  }
  .risk-indicator-container {
    width: 12% !important;
 }
}
@media only screen and (max-width: 1100px) and (min-width: 1000px){
  .report-accordion-container {
    width: 37% !important;
    margin-left: -9% !important;
}
#report .konvajs-content{
  width: 769px !important;
  height: 360px !important;
}
#report canvas{
  width: 769px !important;
  height: 360px !important;
}
.risk-indicator-container {
  width: 14% !important;
}
}
@media only screen and (max-width: 1600px) and (min-width: 1500px){
  #report .konvajs-content{
    width: 1355px !important;
  }
  #report canvas{
    width: 1355px !important;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1300px){
.report-accordion-container{
  width: 29%;
  margin-left: 0;
}
#report .konvajs-content{
  width: 1180px !important;
  height: 508px !important;
}
#report canvas{
  width: 1140px !important;
  height: 508px !important;
}
.risk-indicator-container {
  width: 12% !important;
}
}
.report-diagram {
  // margin-left: 6%;
  width: 71%;
}
.report-accordion-body {
  border-bottom: 1px solid #ced7d4;
  padding-bottom: 10px;
  display: flex;
  margin-left: 15px;
  padding-top: 10px;
}

.accordion-body-val {
  margin-right: 0px;
  font-weight: bold;
}
.accordion-body-label {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
  width: 57%;
}
.accordion-body-label-overhead {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
  width: 77%;
}
.report-accordion-header {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.25px !important;
  padding-left: 10px;
}

.primary-report-header {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: -0.25px !important;
}
.salt-graph-wrapper {
  margin-bottom: 55px;
}
.risk-indicator-container {
  width: 10%;
}
.tool-tip-text {
  margin-left: 10px;
  margin-top: 2px;
}
.tooltip-conatiner {
  width: 125px;
}
.bread-crum-report {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 32px;
  gap: 8px;

  position: absolute;
  width: 98%;
  height: 44px;
  left: 20px;
  top: 71px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Common/Primary */

  border-bottom: 1px solid #ced7d4;
}
.corrosion-risk-container {
  position: absolute;
  top: 200px;
  left: 86px;
  width: 92%;
}
.download-btn {
  position: absolute;
  top: 171px;
  left: 200px;
}
.left-margin-15 {
  margin-left: 15px;
}
.overhead-label {
  margin-left: 2.5%;
  width: 40%;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
}
.overhead-label-50 {
  width: 50%;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 2.5%;
}
.overhead-val {
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  width: 10%;
  color: black;
}
.overhead-div {
  line-height: 20px;
  color: rgba(80, 108, 101, 1);
  background: #f2f6f5;
  width: 92%;
  padding-right: 2%;
}
.corrosion-risk-header {
  font-size: 50px;
  color: rgba(5, 50, 43, 1);
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 31px;
  font-family: Poppins, sans-serif;
  width: 80%;
}
.bh-logo-pdf {
  max-width: 253px;
}
.corrosion-risk-refinery {
  margin-left: 30px;
  font-size: 40px;
  font-family: Poppins, sans-serif;
  color: rgba(89, 89, 89, 1);
  font-weight: 500;
}
.corrosion-risk-label {
  margin-left: 30px;
  font-size: 30px;
  font-family: Poppins, sans-serif;
  color: rgba(89, 89, 89, 1);
  font-weight: 400;
}
.margin-left-13{
  margin-left: 13px;
}
.corrosion-risk-div {
  margin-left: 42px;
}

.margin-top-22{
  margin-top: 22px;
}

.sandbox-title{
    margin-left: 74px;
    width:80%;
    margin-top: 9px;
    height: 28px;
    padding-top: 11px;
    border-radius: 4px;
    gap: 9px;
    background: #FCF5C1;
    border: 1px solid #E8751680;
}

.font-size-30{
  font-size: 30px !important;
}

.text-size--xlarge {
  display: none;
}

.corrosion-pdf {
  position: absolute;
  top: 3500px;
}
.corrosion-risk-pdf-header {
  margin: 50px 30px 15px 30px;
  display: flex;
}
.primary-subheading {
  color: rgba(20, 125, 100, 1);
  font-size: 26px;
}
.primary-report-pdf-unit {
  background-color: rgba(0, 160, 143, 1);
  position: absolute;
  margin-top: 22px;
  margin-left: 74px;
  width: 92%;
  color: white;
  height: 47px;
  display: flex;
}
.primary-report-label {
  font-size: 25px;
  margin-left: 13px;
  font-family: Poppins, sans-serif;
  margin-top: 10px;
  font-weight: 500;
}
.primary-report-atm {
  margin-left: 30px;
  margin-top: 13px;
  letter-spacing: 0.5px;
  font-family: Poppins, sans-serif;
}
.report-diagram-pdf {
  margin-left: 14%;
  margin-top: 10%;
}
.overhead-line-div {
  margin-left: 6%;
}
.recommendations-header {
  font-size: 1.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  margin-left: 5%;
}
.overhead-header {
  font-size: 1.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.recommendations-container {
  min-height: 1950px;
}
.comments-val {
  font-size: 1.25rem;
  font-family: Poppins, sans-serif;
  margin-left: 5%;
  min-height: 2000px;
  position: absolute;
}
.seperator-line {
  width: 90%;
  height: 1px;
  background-color: #939191;
  border: none;
  margin-top: 30px;
}
.salt-temp-header {
  font-size: 1.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.footer-bh-pdf {
  font-family: Noto Sans, sans-serif;
  color: rgba(2, 167, 131, 1);
  font-weight: 500;
  font-size: 1rem;
  margin: 1rem 0 0 0;
}
.footer-copyright-pdf {
  font-family: Noto Sans, sans-serif;
  color: rgba(80, 108, 101, 1);
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  width: 91%;
}
.footer-page-num-pdf {
  font-family: Noto Sans, sans-serif;
  color: rgba(80, 108, 101, 1);
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}
.corrosion-risk-title {
  position: absolute;
  width: 1318px;
  left: 87px;
  top: 130px;
}
.corrosion-risk-footer {
  margin-left: -2.25%;
  width: 104%;
  padding-bottom: 50px;
  margin-top: 24%;
}
.tooltip-all-sim {
  z-index: 9999;
}
.breadcrumbs-report {
  position: absolute;
  top: 83px;
  left: 76px;
  width: 94%;
}
.divder-container {
  top: 34px;
  position: absolute;
  width: 96%;
  left: 60px;
}
.bread-crumb-font {
  font-family: Poppins, sans-serif !important;
  font-size: 14px !important;
  letter-spacing: -0.25px;
}
.inactive-breadcrumb-label {
  font-weight: 500;
  color: #595959;
}
#report-card .MuiCardHeader-title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: inline;
}
#report-card .MuiCardHeader-subheader {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: inline;
  margin-left: 12px;
}
.primary-report-subheader {
  font-family: 'Noto Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 1.75 !important;
  padding-left: 12px !important;
}
.report-outline {
  border: 1px solid;
  border-color: #ced7d4;
  border-radius: 4px;
}
