.heading-wrapper {
  width: 50%;
}
.salt-temp-heading {
  margin-left: 32px;
  color: #506c65;
}
.salt-temp-buttontabs {
  margin-left: 38%;
  margin-top: -8%;
}
@media only screen and (max-width: 1100px) and (min-width: 1000px){
.salt-graph-container canvas{
  width:900px !important;
}
.dewpoint-graph-container canvas{
  width:900px !important;
}
.salt-temp-buttontabs {
  margin-left: 57% !important;
  margin-top: -12% !important;
}
.dew-temp-buttontabs {
  margin-left: 63% !important;
  margin-top: -12% !important;
}
}
@media only screen and (max-width: 1300px) and (min-width: 1200px){
  .salt-temp-buttontabs {
    margin-left: 49% !important;
    margin-top: -10% !important;
  }
  .dew-temp-buttontabs {
    margin-left: 55% !important;
    margin-top: -10% !important;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1300px){
  .salt-temp-buttontabs {
    margin-left: 45% !important;
    margin-top: -9% !important;
  }
  .dew-temp-buttontabs {
    margin-left: 50% !important;
    margin-top: -9% !important;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1500px){
  .dew-temp-buttontabs {
    margin-left: 44% !important;
}
}
@media only screen and (max-width: 1920px) and (min-width: 1600px){
  .salt-temp-buttontabs {
    margin-left: 35% !important;
    margin-top: -7% !important;
}
.dew-temp-buttontabs {
  margin-left: 39% !important;
  margin-top: -7% !important;
}
}
@media only screen and (max-width: 2100px) and (min-width: 2000px){
  .salt-temp-buttontabs {
    margin-left: 29% !important;
    margin-top: -6% !important;
 }
 .dew-temp-buttontabs {
  margin-left: 32% !important;
  margin-top: -6% !important;
 }
}
.salt-graph-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 1081.08px;
  height: 388px;
  left: 94px;
  top: 57px;
}
.dewpoint-graph-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 36px;
  width: 1081.08px;
  height: 397px;
  left: 94px;
  top: 574px;
}
.dew-temp-buttontabs {
  margin-left: 42%;
  margin-top: -8%;
}
.salt-graph-container-pdf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 1081.08px;
  height: 330px;
  left: 94px;
  top: 57px;
}

.dewpoint-graph-container-pdf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 36px;
  width: 1081.08px;
  height: 330px;
  left: 94px;
  top: 574px;
}
