#bh-change-unit .MuiTreeView-root {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
#nodisplay-alert {
  display: none;
}
.css-1g86id8-MuiTreeItem-content.Mui-selected {
  background-color: #c6f7e2 !important;
}
#txt-search .bh-text-input__input {
  width: 100% !important;
}
.css-1g86id8-MuiTreeItem-content .MuiTreeItem-label {
  line-height: 1.8 !important;
}
@media screen and (max-width: 1920px) {
  .alert-message-changeunit{
  width: 37%;
  top: 48%;
  }
}
@media screen and (max-width: 1500px) {
  .alert-message-changeunit{
    width: 46.4%;
    top: 53%;
    }
}
@media screen and (max-width: 1700px) {
  .alert-message-changeunit{
     width: 46.3%;
     top: 53%;
    }
}
@media screen and (max-width: 1600px) {
  .alert-message-changeunit{
      width: 46.4%;
      top: 52%;
    }
}
@media screen and (max-width: 1400px) {
  .alert-message-changeunit {
    width: 52.5%;
    top: 52%;
  }
}
@media screen and (max-width: 1300px) {
  .alert-message-changeunit{
    width: 53% ;
    top:56%;
    }
}

@media screen and (max-width: 1200px) {
  .alert-message-changeunit{
    width: 53% ;
    top:56%;
    }
}
@media screen and (max-width: 1358px) {
  .alert-message-changeunit{
    width: 53% ;
    top:56%;
    }
}
#alert-message-changeunit{
  position: fixed;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: #121212;
  margin-top: 3px;
}
.alert-box-success {
  margin-top: 10px;
  background-color: #effcf6 !important;
  border: 1px solid;
  border-color: #05322b;
}
#alert-message .MuiAlert-message {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: #121212;
  margin-top: 3px;
}
.alert-box-error {
  margin-top: 10px;
  background-color: #feeaee !important;
  border: 1px solid;
  border-color: #e12d39;
}
#change-unit-modal .bh-modal__container {
  height: 80% !important;
  max-height: 80% !important;
  overflow: visible !important;
}
 
#change-unit-modal .bh-modal__body {
  height: 50% !important;
  overflow-y: scroll;
  max-height: 50% !important;
}
