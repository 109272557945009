.report-comment-wrapper {
  margin-bottom: 10px;
}
.btn-comment-wrapper {
  display: flex;
  justify-content: flex-end;
}
.btn-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
}
.btn-clear {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.alert-box-wd {
  width: 96.5%;
}
.modified-by {
  margin-top: 3px;
  color: #595959;
}
