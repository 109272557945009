.user-card-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 95%;
  left: 72px;
  top: 32%;
  background: #ffffff;
  border: 1px solid #ced7d4;
  border-radius: 4px;
}
#user-card .MuiCardHeader-title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.user-action-wrapper {
  padding-right: 10px;
}
.user-action-container {
  display: inline-flex;
}
#txt-admin-search {
  height: 27px;
  width: 152px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.search-wrapper .MuiTextField-root:focus {
  outline: none !important;
  border-color: var(--color-border-form-focused) !important;
  box-shadow: var(--effect-drop-shadow-focus-primary) !important;
}
.tp-150 {
  top: 150%;
}
.tp-22 {
  padding-top: 22px;
}
#permission-required {
  padding-top: 15%;
}
.filter-drd {
  width: 152px;
}
.filter-drd .bh-dropdown__input {
  width: 94px;
}
#btnAddUser .bh-button--type-primary {
  height: 44px;
}
.asset-error {
  top: 8%;
  position: absolute;
  height: 37px;
  left: 34%;
  width: 32%;
}
#refinery .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
#customer .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
.edit-data{
  display: inline-flex;
  gap: 20px;
}
.changeset-li{
  list-style-type: none;
}
#change-request-card .MuiCardHeader-title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.textSize_Adj h2 {
  font-size: 14px;
}

.mainHead  {
  display: flex;
  justify-content: space-between;
  height: '90px';
}

.mainHeadContent {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.normalSpaceDiv {
  width: 27%;
  display: flex;
  flex-direction: column;
}

.largeSpaceDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.viewRequestHead {
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}

.buttonArea {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.buttonRightSide {
  display: flex;
  justify-content: row;
}

.approveButton {
  margin-right: 20px;
}

.tableArea {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
}

#request-modal .bh-modal__body {
  height: 68% !important;
  overflow-y: scroll;
  max-height: 88% !important;
}

#request-modal .bh-modal__container {
  width: 85% !important;
  height: 95% !important;
  left: 8% !important;
  top: 1%;
  z-index: 40000;
}

.request-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 565px;
  height: 478px;
}

#current-modal .bh-modal__body {
  height: 68% !important;
  overflow-y: scroll;
  max-height: 88% !important;
}

#current-modal .bh-modal__container {
  width: 85% !important;
  height: 95% !important;
  left: 8% !important;
  top: 1%;
  z-index: 50000;
}
#edit-asset-modal .bh-modal__container{
 z-index: 40000;
}

.current-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 565px;
  height: 478px;
}

.green  {
  color: #02a783;
  font-weight: bold;
  font-style: italic;
}

#approver .bh-text-area__input {
  width: 97% !important;
}

#request .bh-text-area__input {
  width: 97% !important;
}
#expiry .bh-text-area__input {
  width: 97%;
}
#expiryhistory .bh-text-area__input {
  width: 97%;
  height: 20px;
}
.pd-10{
  padding-left: 10px;
}
.pd-top{
  padding-top: 10px;
}
#startDate__vaadin-date-picker{
 width:65%;
}
#endDate__vaadin-date-picker{
  width:65%;
 }
 .date-margin-left{
  margin-left: 68px;
 }
 .coment-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
 }
 .mg-top-30{
  margin-top: 30px;
 }
 .wd-48{
  width: 48%;
 }
 .wd-80{
  width: 80%;
 }
 .end-date-pick {
  width: 150px;
}
.end-date-pick .MuiInputBase-formControl {
  height: 44px;
}

.admin-sub-head {
  margin-left: 20px;
  margin-top: -17px;
  height: 30px
}

.admin-divider {
  margin-top: -15px;
}

.admin-breadCrumb {
  position: absolute;
}