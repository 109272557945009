.progress-spinner {
  position: absolute;
  top: 15px;
}
.progress-span {
  top: 12px;
  position: absolute;
  left: 30px;
}
.done-span {
  position: absolute;
  left: 32px;
}
.error-span {
  position: absolute;
  left: 35px;
}
.sp-inprogress .bh-spinner-container--inline .text-size--small {
  display: none;
}
.report-underline {
  color: var(--color-text-link-hover);
  text-decoration: underline;
  cursor: pointer;
}

.simulation-table-width {
  height: 400;
  width: 100%;
}
