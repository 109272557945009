.bh-card-dashed {
  box-sizing: border-box;

  //position: absolute;
  width: 100%;
  height: 340px !important;

  background: #f8faf9;
  /* 🎨 Border/CTA/Secondary Hover */

  border: 1px dashed #8ca39d;
}
.bh-card-dashed label[class^='sc-'] {
  // padding-top: 80px !important;
  border: none !important;
  // padding-left: 160px !important;
  height: 150px !important;
  margin-left: 165px !important;
  min-width: 175px !important;
  width: 175px !important;
}
.bh-card-dashed label svg {
  visibility: hidden;
}
.bh-card-dashed span[class^='sc-'] {
  visibility: hidden;
}
.upload-icon-wrapper {
  position: absolute;
  left: 47%;
  top: 90px;
}
.bh-icon-container {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 28%;
  left: 44%;
  /* 🎨 Fill/Common/Tertiary */

  background: #ebefee;
  border-radius: 12px;
}
.icon-box {
  padding-left: 30%;
  padding-top: 29%;
  position: absolute;
}
.icon-desc {
  top: 165px;
  position: absolute;
  left: 41%;
}
#bh-upload-excel .bh-modal__body {
  height: 440px !important;
}
.btn-wrapper {
  top: 57%;
  position: absolute;
  width: 26%;
}
.btn-download {
  position: absolute;
  left: 57px;
}
#btn-upload {
  left: 202%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 4px;
  text-transform: capitalize;
  /* 🎨 Fill/CTA/Primary Default */

  background: #147d64;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.upload-footer {
  position: absolute;
  width: 0px;
  top: 89.5%;
  left: 64.5%;
  height: 0px;
}
.input-dropdown {
  display: flex;
  width: 145px;
  left: 8px;
}
#bh-upload-excel .bh-modal__footer .bh-modal__footer--ctas .bh-modal__footer--cta .bh-button__container .bh-button--type-ghost {
  position: absolute;
  left: 52%;
  height: 41px;
}
#bh-upload-excel .bh-modal__footer .bh-modal__footer--ctas .bh-modal__footer--cta .bh-button__container .bh-button--type-primary {
  height: 41px;
}
.dzu-dropzone {
  border: none;
  min-height: 200px;
}
.upload-icon-success {
  position: absolute;
  left: 5.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
  color: #147d64;
}
.filename-span {
  position: absolute;
  top: 6.5%;
  left: 9%;
}
.dzu-submitButtonContainer {
  display: none;
}
.upload-modal {
  width: 100%;
  height: 100%;
}
.upload-modal .MuiDialog-paperScrollPaper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: unset;
  position: absolute;
  width: 783px;
  height: 500px;

  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
}
.upload-modal .MuiDialogContent-dividers {
  width: 94.6%;
}
.upload-modal .MuiDialogActions-spacing {
  padding-left: 27% !important;
}
.upload-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.25px;

  /* 🎨 Text/Common/Primary */

  color: #121212;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}
.p-left {
  position: absolute;
  left: 35%;
  top: 40%;
  width:80%;
  word-wrap: break-word;
}
.p-left-7 {
  position: absolute;
  left: 10%;
  width:80%;
  word-wrap: break-word;
}
.done-icon {
  position: absolute;
  left: -23px;
}
.btn-hide {
  display: none;
}
.btn-show {
  display: block;
}
#btn-upload-another {
  display: flex;
  padding: 0px 14px;
  width: 136px;
  height: 36px;
  border: 1px solid #adbdb9;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: #121212;
  background-color: white;
  position: absolute;
  top: 16%;
}
.margin-left {
  margin-left: 40px !important;
}
.error-message {
  padding-left: 7px;
  top: 3px;
  position: absolute;
  flex-flow: wrap;
  width: 450px;
}
.errorinfo-padding {
  padding-left: 25px;
}
#error-dialogue .MuiDialog-paperWidthSm {
  height: 400px;
  width: 100%;
}
.btn-next{
  margin-right: -75%;
}
