.dashboardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 310px);

    #permission-required {
        padding-top: 0px !important;
    }
}

.addFavoriteBtn {
    margin-top: 30px;
}

.favoriteList {
    min-height: 220px;

    .MuiDataGrid-root {
        border-style: hidden;
    }

    .MuiDataGrid-root {
        border-style: hidden;
    }

    .MuiDataGrid-columnHeader .MuiDataGrid-withBorderColor {
        background: #effcf6 !important;
    }

    .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer {
        background: #effcf6 !important;
    }

    .MuiDataGrid-columnHeaderDraggableContainer {
        height: 60px !important;
    }

}


.headerLeft {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.25px;
}

.grid-header-favorite {
    color: var(--Earth-800, #334541);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    background-color: #effcf6;
}

.firstColumn {
    display: flex;
    flex-direction: column;
}

.colorText {
    font-family: Poppins, sans-serif;
    color: var(--Text-1, #1A2321);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
}

.numberText {
    color: var(--Teal-700, #147D64);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.25px;
}

.numberTextWithUnderLine {
    color: var(--Teal-700, #147D64);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.25px;
    text-decoration-line: underline;
    cursor: pointer;
}

.underLineText {
    color: var(--Teal-500, #02A783);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.25px;
    text-decoration-line: underline;
    cursor: pointer;
}

.labelTextTable {
    color: var(--Text-2, #757575);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.normalText {
    color: var(--Text-1, #1A2321);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
}

.favIcon {
    color: var(--Teal-500, #02A783);
}

.labelAndIcon {
    display: flex;
}

.iconDiv {
    margin-top: -7px;
}

.addBtn {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
    margin-top: -30px;
}

.card-wrapper-favorite {
    min-height: 540px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    bottom: 126px;
    background: #ffffff;
    border: 1px solid #ced7d4;
    border-radius: 4px;
}

.forText {
    .typography--body-small {
        color: var(--Text-Common-Primary, #121212);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        /* 🖌 Title/🖌 Subtitle/Medium */
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        /* 137.5% */
        letter-spacing: -0.25px;
        width: 300px !important;
        max-width: 300px !important;
    }

}

.treeTest {
    .MuiTreeItem-label {
        font-family: Poppins, sans-serif !important;
    }
}

.no-data-text {
    color: #BDBDBD;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.navigateTextDiv {
    display: flex;
}

.navigateTextBha {
    margin-left: 2px;
}

.delete-dialog {
    .typography--body-small {
        font-size: 13px;
    }
}

.dividerForSub {
    color: var(--Text-2, #757575);
}

.labelHeight {
    margin-top: -7px;
}