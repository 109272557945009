.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  width: 1440px;
  height: 998px;

  /* Background / 1 */

  background: #f8faf9;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.grid-wrapper {
  position: absolute;
  visibility: hidden;
  width: 1378px;
  left: 62px;
  top: 72px;
  bottom: 39px;
}
.sidebar-wrapper {
  position: fixed;
  width: 58px;
  height: 100%;
  left: 0px;
  top: 72px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
}
.bread-crum {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 32px;
  gap: 8px;

  position: absolute;
  width: 100%;
  height: 44px;
  left: 20px;
  top: 71px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Common/Primary */

  border-bottom: 1px solid #ced7d4;
}

.bh-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 32px 14px 20px;
  gap: 706px;

  position: absolute;
  width: 1440px;
  height: 40px;
  left: 0px;
  top: 0px;

  /* 🎨 Fill/Common/Brand */

  background: #05322b;
}
.logoutMenuDropdown {
  position: absolute;
  right: 0;
  top: 65px;
  overflow-y: hidden;
}

.logoutMenuButton div {
  padding-top: 0px;
  padding-left: 23%;
}
.bh-header__app-name {
  visibility: hidden;
  position: relative;
}
.bh-header__app-name:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: 'TOPGUARD™ Corrosion Risk Monitor';
  width: 320%;
}
/* //material ui data grid styles override */
.grid_container {
  background-color: #fff;
  overflow: visible;
  position: relative;
  display: block;
  margin-bottom: 20%;
  padding-left: 4%;
  width: 100%;
}
.grid-header {
  background-color: #ebefee;
  color: #121212;
  font-weight: 500;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  min-height: 42px;
}
.MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer {
  background-color: #ebefee;
}
.Mui-checked {
  color: #02a783 !important;
}
.MuiDataGrid-row {
  background-color: #fff !important;
}
.MuiPaginationItem-circular.MuiPaginationItem-textPrimary.Mui-selected {
  color: #121212;
  background-color: #ebefee !important;
}
.MuiMenuItem-gutters.Mui-selected.MuiTablePagination-menuItem {
  background-color: #c6f7e2;
  color: #121212;
}
.MuiDataGrid-checkboxInput {
  border-color: #a0a0a0 !important;
  border-style: solid !important;
}
.MuiDataGrid-cell {
  color: #595959;
}
.date-link {
  text-decoration: underline !important;
  cursor: pointer !important;
  color: #17afa1;
}
.text-link {
  display: flex;
  align-items: center;
}
.text-link-pointer {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-wrap: pretty;
}
.sim-name-span{
  padding-top: 1px;
  display: flex;
  text-wrap: pretty;
}
.text-margin {
  margin-left: var(--spacing-margin-xsmall);
}
.help-wrapper {
  margin-left: 20px;
}
#help-menu {
  z-index: 9999;
}
#help-menu .MuiPaper-root {
  left: 77% !important;
  top: 57px !important;
}
#help-menu .MuiMenuItem-gutters {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}
#help-menu .MuiMenu-list {
  width: 250px;
}
#help-menu .MuiMenuItem-gutters:hover {
  background-color: #c6f7e2;
  color: black;
}
.sandbox-wrapper{
height: calc(100vh - 72px);
margin-top: 72px;
position: relative;
overflow-y: auto;
}
.sandbox-container{
    height:72px;
    z-index: 2200;
    background-color: var(--color-fill-common-brand);
    height: var(--header-desktop-height);
    display: flex;
    align-items: center;
    z-index: 2000;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding-left: 16px;
    padding-right: 32px;
}
.sandbox-btn
{
   position: fixed;
    z-index: 4000;
    top: 3%;
    width: 10%;
}
@media only screen and (max-width: 1000px) and (min-width: 900px){
  .sandbox-btn {
    left: 65%;
    width: 17%;
    top: 5.6%;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 1000px){
  .sandbox-btn {
    left: 70%;
    width: 15%;
    top:4.5%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1100px){
  .sandbox-btn {
    left: 71%;
    width: 14%;
    top:4%;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1200px){
  .sandbox-btn {
    left: 73%;
    width: 13%;
    top:4%;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1300px){
  .sandbox-btn {
    left: 76%;
    width: 12%;
    top:4%;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1400px){
  .sandbox-btn {
    left: 78%;
    width: 12%;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1500px){
  .sandbox-btn {
    left: 78%;
    width: 12%;
    top:3.5%;
  }
}
@media only screen and (max-width: 1700px) and (min-width: 1600px){
  .sandbox-btn {
    left:80%;
  }
}
@media only screen and (max-width: 1800px) and (min-width: 1700px){
  .sandbox-btn {
    left:80%;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1800px){
  .sandbox-btn {
    left:81%;
  }
}
@media only screen and (max-width: 2000px) and (min-width: 1920px){
  .sandbox-btn {
    left:81%;
  }
}
@media only screen and (max-width: 2100px) and (min-width: 2000px){
  .sandbox-btn {
    left:82.5%;
    top:2.7%;
  }
}
@media only screen and (max-width: 2200px) and (min-width: 2100px){
  .sandbox-btn {
    left:84%;
    top: 4%;
  }
}
@media only screen and (max-width: 2300px) and (min-width: 2200px){
  .sandbox-btn {
    left:85%;
    top: 4%;
  }
}
@media only screen and (max-width: 2900px) and (min-width: 2300px){
  .sandbox-btn {
    left:85%;
    top: 4%;
  }
}
#btnSandbox .bh-toggle__input-container .bh-toggle__label {
  color:#ffffff !important;
}
.sandbox-alert{
  position: fixed;
  width: 53%;
  left: 20%;
  top: 13%;
  z-index: 4000;
}
@media screen and (max-width: 1200px) {
  .sandbox-alert {
    width: 50%;
    height:57px;
  }
}
#banner { 
  position: fixed;
  top: 72px;
  left: 0; 
  background-color: #FADB5F; 
  width: 100%; 
  z-index: 20000;
  height:14px;
}
#banner-content { 
  width: 800px; 
  margin: 0 auto; 
  top: -3px;
  position: relative;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
#my-appshell{
  overflow-y: auto;
}
.banner-show{
 display: block;
}
.banner-hide{
  display: none;
 }
