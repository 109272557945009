.new-sim-data {
  position: absolute;
  width: 1318px;
  height: 50px;
  left: 87px;
  top: 111px;
}
.sim-data-card {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  // position: absolute;
  width: 100%;
  //height: 970px;
  // left: 90px;
  margin-top: 50px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Form/Default */

  border: 1px solid #ced7d4;
  border-radius: 4px;
  //overflow: auto;
}
.desalter-acc {
  padding: 0px;

  position: absolute;
  width: 1278px;
  left: 20px;
  top: 94px;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 20px;
  padding-left: 25px;
}
.input-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  // width: 1087px;
  // height: 66px;
  flex: none;
  flex-grow: 0;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.wd-341 {
  width: 341px;
}
.tower-acc {
  padding: 0px;
  width: 1278px;
  height: 338px;
  background: #ffffff;
  border-bottom: 1px solid #ced7d4;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  left: 6px;
  top: 513px;
}
.overhead-acc {
  padding: 0px;
  width: 1278px;
  height: 338px;
  background: #ffffff;
  border-bottom: 1px solid #ced7d4;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  left: 110px;
  top: 779px;
}
.bh-accordion--content {
  border-bottom: none;
}
.bh-accordion {
  padding-top: 10px;
}
.bh-dropdown__input {
  height: 19px;
}
.date-div {
  padding-top: 7px;
}
.input-wrapper .bh-text-input__input {
  width: 341px;
  height: 44px;
}
.text-unit .bh-text-input__input {
  width: 192px;
  height: 44px;
}

#washrate .bh-text-input__input {
  width: 240px !important;
}
.data-unit {
  width: 341px;
  height: 44px;
}

.data-unit .bh-dropdown__input {
  width: 286px;
}

.dropdown-unit {
  width: 141px;
  height: 44px;
  margin-left: -23px;
}
.dropdown-unit .bh-dropdown__input{
  width: 85px;
}

#wash-rate-unit .bh-dropdown__input{
  width: 35px;
}
#wash-rate-unit .typography--icon-small {
  margin-right: 48px !important;
}
#date__vaadin-date-picker {
  width: 341px;
  height: 34px;
}
#date-input-error .bh-form-message--error {
  padding-top: 15px;
}
.div-hide {
  display: none;
}
.div-show {
  display: block;
}
.accordion-hide {
  display: none;
}
.accordion-show {
  display: inline-flex;
}
.sutitle-left {
  margin-left: 25px;
  padding-top: 20px;
}
.hr-border {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin-top: 32px;
}
.date-pick {
  width: 341px;
}
.date-pick .MuiInputBase-formControl {
  height: 44px;
}
.MuiPickersPopper-root {
  z-index: 43000 !important;
}
.MuiPickersCalendarHeader-label {
  font-family: 'Poppins', sans-serif;
  color: #121212;
  font-size: 14px;
  margin: 8px;
}
.MuiDayCalendar-header .MuiDayCalendar-weekDayLabel {
  font-family: 'Poppins', sans-serif;
  color: #595959;
  font-size: 12px;
}
.MuiDayCalendar-monthContainer .MuiPickersDay-dayWithMargin {
  font-family: 'Noto Sans', sans-serif;
  color: #121212;
  font-size: 14px;
  transition: none;
}
.MuiDayCalendar-weekContainer .Mui-selected {
  color: #fff;
  border-radius: 4px;
  background-color: #147d64 !important;
}
.date-div .MuiOutlinedInput-input {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 500;
  color: #121212;
  border-radius: 4px;
  font-size: 14px;
}
.MuiPickersYear-root .MuiPickersYear-yearButton {
  font-family: 'Noto Sans', sans-serif;
  color: #121212;
  font-weight: 400;
}
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected {
  color: #fff;
  border-radius: 4px;
  background-color: #147d64 !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  outline: none;
  border-color: #02a783 !important;
  box-shadow: 0px 0px 0px 2px #8eedc7 !important;
  border-width: 0px;
}
.info-message {
  margin-left: -22px;
  top: 20px;
  position: relative;
  color: #e87516;
}
.info-message-1A-desalttemp {
  margin-left: -141px;
  top: 67px;
  position: relative;
  color: #e87516;
}
.div-show-deslater {
  display: inline-block;
}
.input-accordion-title .bh-accordion--header .bh-accordion--title{
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
.sutitle-left .bh-title-wrapper .bh-title-wrapper__container .bh-title-wrapper__subtext-copy{
  font-weight: 500;
    font-size: 13px;
}
.date-div-coker {
  padding-top: 1px;
}