.edit-config-wrapper {
  position: absolute;
  width: 1181px;
  height: 738px;
  left: 130px;
  top: 131px;
}

.config-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 565px;
  height: 478px;
  //left: 150px;
  //top: 216px;
}

.preflash-acc {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 565px;
  height: 334px;

  /* 🎨 Fill/Common/Secondary */

  background: #ffffff;
  /* 🎨 Border/Common/Primary */

  border-bottom: 1px solid #ced7d4;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.preflash-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  position: absolute;
  width: 240px;
  height: 234px;
  left: 20px;
  top: 20px;
}

.preflash-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 565px;
  height: 478px;
}

.overhead-config {
  width: 231px;
  height: 52px;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.radio-btn-grp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;

  position: absolute;
  width: 265px;
  // height: 24px;
  left: 0px;
}

.top-28 {
  top: 28px;
}

.top-112 {
  top: 112px;
}

.preflash-config {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 240px;
  height: 66px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.velocity-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  position: absolute;
  width: 512px;
  height: 236px;
  left: 20px;
  top: 20px;
}

.velocity-config {
  width: 460px;
}

.pipe-diameter-config {
  display: flex;
  flex-direction: row;
  //align-items: flex-start;
  //padding: 0px;
  gap: 32px;

  //width: 512px;
  //height: 66px;

  /* Inside auto layout */

  flex: none;
  //order: 1;
  flex-grow: 0;
}

.acc-wrapper {
  width: 555px;
  height: 478px;
  overflow-y: auto;
  padding-top: 25px;
}

.wd-240 {
  width: 240px;
}

.wd-350 {
  width: 350px;
}

#preflash-config .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
#preflash-config .bh-dropdown__input{
  width:185px;
}
#preflash-config .bh-tabular-list__tooltip.shown {
  opacity: 0 !important;
}
#pipe-diameter-unit .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
#spray-length-unit .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
#spray-unit .bh-dropdown__container .bh-dropdown__value {
  box-sizing: border-box !important;
}
#pipe-diameter-txt .bh-text-input__input {
  width: 140px;
}

.wd-140 {
  width: 140px;
}

.preview-card {
  position: absolute;
  width: 544px;
  height: 475px;
  left: 595px;
  top: 25px;
}

.modal-wrapper {
  width: 1181px;
  height: 738px;
}

#inc-branch .bh-text-input__container {
  width: 240px;
}

#config-modal .bh-modal__container {
  width: 85% !important;
  height: 95% !important;
  left: 8% !important;
  top: 1%;
}

.diameter-desc {
  text-align: justify;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* Typography / text-2 (Body) */

  color: #757575;
}

#panel1-header .MuiTypography-root {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #595959 !important;
}

#panel1-header .MuiAccordionSummary-expandIconWrapper {
  width: 18px;
  height: 18px;
}

#panel2-header .MuiTypography-root {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* 🎨 Text/Common/Secondary */

  color: #595959;
}

#panel2-header .MuiAccordionSummary-expandIconWrapper {
  width: 18px;
  height: 18px;
}

#panel3-header .MuiTypography-root {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* 🎨 Text/Common/Secondary */

  color: #595959;
}

#panel3-header .MuiAccordionSummary-expandIconWrapper {
  width: 18px;
  height: 18px;
}

#panel4-header .MuiTypography-root {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* 🎨 Text/Common/Secondary */

  color: #595959;
}

#panel5-header .MuiTypography-root {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
}


#panel4-header .MuiAccordionSummary-expandIconWrapper {
  width: 18px;
  height: 18px;
}

#panel5-header .MuiAccordionSummary-expandIconWrapper {
  width: 18px;
  height: 18px;
}

.hgt-44 {
  height: 44px;
  //margin-top: 22px;
  border: 1px solid #ced7d4;
  border-radius: 4px;
}

.margin-tp15 {
  margin-top: 15px;
}

.preflash-asterisk {
  display: inline-flex;
  position: absolute;
  color: red;
  left: 165px;
  top: 200px;
}

#nodisplay-alert {
  display: none;
}

@media screen and (max-width: 1500px) {
  .config-error {
    position: absolute;
    bottom: 170px;
    width: 94%;
  }
}

@media screen and (max-width: 1700px) {
  .config-error {
    position: absolute;
    bottom: 155px;
    width: 94%;
  }
}

@media screen and (max-width: 1200px) {
  .config-error {
    position: absolute;
    bottom: 120px;
    width: 94%;
  }
}

@media screen and (max-width: 1358px) {
  .config-error {
    position: absolute;
    bottom: 100px;
    width: 92%;
  }
}

#config-modal .bh-modal__body {
  height: 68% !important;
  // min-height: 70%;
  overflow-y: scroll;
  max-height: 88% !important;
  //min-height: 350px;
}

#pipe-diameter .MuiFormControl-root .MuiInputBase-root {
  width: 240px;
  height: 44px;
}

.textSize_Adj h2 {
  font-size: 14px;
}

.velocity-dropdown-unit {
  width: 96px;
  height: 44px;
  margin-left: -23px;
}

.spray-dropdown-unit {
  width: 96px;
  height: 44px;
}

.spray-dropdown-unit .bh-dropdown__input{
  width: 41px;
}

.div-for-divide {
 width:100px;
 margin-right: 15px;
}


.div-width-280 {
    width:280px;
    margin-top: -6px;
}

.div-for-divide-250 {
  width:250px;
  margin-right: 15px;
 }

 .div-for-divide-250-length {
  width:250px;
  margin-right: 10px;
 }

 .div-for-pipe-diameter {
  display:flex;
  justify-content :row;
  width:600px
 }

 .div-for-pipe-length {
  display:flex;
  justify-content :row;
  width:600px
 }

.spray-length-dropdown-unit {
  width: 96px;
  height: 44px;
}

.spray-length-dropdown-unit .bh-dropdown__input{
  width: 41px;
}

.spray-Pipe .bh-text-input__container{
  width: 245px;
}

.spray-Pipe-length .bh-text-input__container{
  width: 245px;
}

.spray-nozle-unit .MuiFormControl-root .MuiInputBase-root {
 width:  360px !important;
 height: 44.6px !important;
}



.velocity-dropdown-unit .bh-dropdown__input{
  width: 41px;
}
.uom-category {
  display: inline-block;
  margin-right: 45px;
  margin-bottom: 15px;
}

#uom-subcategory-tab .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  color: #929292;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize !important;
  align-items: flex-start !important;
}

#uom-subcategory-tab .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  background-color: #c6f7e2 !important;
  color: #121212 !important;
}

#edit-config-tab .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  color: #929292;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize !important;
}

#edit-config-tab .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: #121212 !important;
  font-weight: 500;
}

#edit-config-tab .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #41937f !important;
  font-weight: 500;
}

#main-tabpanel-0 .MuiBox-root {
  padding: 0 !important;
}

#main-tabpanel-1 .MuiBox-root {
  padding-top: 2px !important;
  padding-left: 0px !important;
}

#main-tabpanel-1 .MuiBox-root .MuiTypography-root {
  padding-top: 2px !important;
}

#main-tabpanel-2 .MuiBox-root {
  padding: 0 !important;
}

.sub-cat-wrapper {
  padding-top: 2%;
  padding-left: 3%;
}

.MuiBox-root {
  height: 500px !important;
}

#uom-subcategory-tab {
  width: 240px;
}

.uom-tab-container {
  width: 880px;
}

.MuiTabs-indicator {
  background-color: unset !important;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.advanced-wrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 70%;
  padding-top: 20px;
  padding-left: 20px;
  gap: 20px;
}

.spray-model-wrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 70%;
  gap: 20px;
}

.advanced-velocityrisk {
  display: flex;
  flex-direction: row;
  gap: 5%;
  padding-top: 0.5%;
}

.pd-3 {
  padding-top: 3%;
}

.pd-1 {
  padding-top: 0.2%;
}

.pd-2 {
  padding-top: 2%;
}

#velocity-risk .bh-text-input__container {
  width: 240px;
}

#lower-ph .bh-text-input__container {
  width: 240px;
}

#tube-skin .bh-text-input__container {
  width: 240px;
}

#ammonia-factor .bh-text-input__container {
  width: 240px;
}

#reflux-fr .bh-text-input__container {
  width: 240px;
}

.restore-ids {
  margin-right: 25px !important;
  color: #3a3b3b !important;
  font-size: 14px;
  font-weight: bolder;
}

#panel1-header .value-changed {
  color: #02a783 !important;
  font-style: italic !important;
}

#panel2-header .value-changed {
  color: #02a783 !important;
  font-style: italic !important;
}

#panel3-header .value-changed {
  color: #02a783 !important;
  font-style: italic !important;
}

#panel4-header .value-changed {
  color: #02a783 !important;
  font-style: italic !important;
}

#panel5-header .value-changed {
  color: #02a783 !important;
  font-style: italic !important;
}



#confirmation-modal .bh-modal__body {
  height: 63% !important;
  overflow: hidden !important;
}

#confirmation-modal .bh-modal__header {
  background: white !important;
}
#confirmation-modal .bh-button__container{
  position: relative;
  bottom: 25%;
}



@media (max-width: 1920px) {
  #confirmationDate #confirmationDate__vaadin-date-picker {
    width: 180px;
  }

  #confirmationDate .bh-date-range-picker__start-date-picker {
    width: 180px;
  }

  #confirmationDate .bh-date-range-picker__end-date-picker {
    width: 180px;
  }

  #comments .bh-text-area__input {
    width: 355px !important;
  }

  #confirmation-modal .bh-modal__container {
    width: 24% !important;
    left: 40% !important;
    top: 150px !important;
    height: 60% !important;
  }

  #confirmation-modal .bh-modal__header-copy--subheader {
    width: 340px !important;
  }
}

@media (max-width: 1536px) {
  #confirmationDate #confirmationDate__vaadin-date-picker {
    width: 160px;
  }

  #confirmationDate .bh-date-range-picker__start-date-picker {
    width: 160px;
  }

  #confirmationDate .bh-date-range-picker__end-date-picker {
    width: 160px;
  }

  #comments .bh-text-area__input {
    width: 320px !important;
  }

  #confirmation-modal .bh-modal__container {
    width: 26% !important;
    left: 40% !important;
    top: 150px !important;
    height: 69% !important;
  }

  #confirmation-modal .bh-modal__header-copy--subheader {
    width: 340px !important;
  }
}
@media (max-width: 1358px) {
  #confirmationDate #confirmationDate__vaadin-date-picker {
    width: 160px;
  }

  #confirmationDate .bh-date-range-picker__start-date-picker {
    width: 160px;
  }

  #confirmationDate .bh-date-range-picker__end-date-picker {
    width: 160px;
  }

  #comments .bh-text-area__input {
    width: 320px !important;
  }

  #confirmation-modal .bh-modal__container {
    width: 33% !important;
    left: 40% !important;
    top: 18% !important;
    height: 79% !important;
  }
  #confirmation-modal .bh-modal__body .confirmation-modal-wrapper{
    padding-left: 10px;
  }

  #confirmation-modal .bh-modal__header-copy--subheader {
    width: 340px !important;
  }
}

@media only screen and (max-width: 2200px) and (min-width: 2000px){
  #confirmation-modal .bh-modal__container {
  width: 26% !important;
  left: 40% !important;
  top: 150px !important;
  height: 60% !important;
  }
  #comments.bh-text-area__input {
    width: 477px;
  }
}
@media only screen and (max-width: 2600px) and (min-width: 2400px){
  #confirmation-modal .bh-modal__container {
    width: 26% !important;
    left: 40% !important;
    top: 150px !important;
    height: 60% !important;
    }
}

@media only screen and (max-width: 2400px) and (min-width: 2300px){
  #confirmation-modal .bh-modal__container {
    width: 26% !important;
    left: 40% !important;
    top: 150px !important;
    height: 60% !important;
    }
    #comments.bh-text-area__input {
      width: 470px;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px){
  #confirmation-modal .bh-modal__container {
    width: 37% !important;
    left: 40% !important;
    top: 55px !important;
    height: 89% !important;
  }
}

#confirmationDate #confirmationDate__vaadin-date-picker span {
  display: none;
}

#overlay {
  z-index: 99999 !important;
}

.restoreLabel {
  font-family: "Poppins", sans-serif;
  color: #121212;
}

.historyIcon {
  margin-right: 5px;
  margin-top: -2px; 
  color: #121212;
}

.restoreDiv {
  cursor: pointer;
  display: flex;
  justify-content: end;
  width: 57%;
  height: 12px;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  left: 37%;
  z-index: 300;
}

.restoreDiv:hover {
   opacity: 0.8;
}  
.custom-required {
  color: #adbdb9;
}

.autoCompleteInput {
  position: absolute;
  margin-top: 0px;
  color: #e12d39;
  font-size: 12px;
  font-weight: 400;
}

.autoDiv {
 margin-bottom: 5px;
}